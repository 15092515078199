var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('solution-filter',{on:{"getData":function($event){return _vm.getData($event)}}}),_c('data-table',{attrs:{"headers":_vm.tableData.headers,"data":_vm.tableData.data,"loader":_vm.loader,"meta":_vm.pagination},on:{"updatePage":function($event){return _vm.getData({ page: $event })}},scopedSlots:_vm._u([{key:"header:status",fn:function(){return [_vm._v(_vm._s(_vm.$t("status")))]},proxy:true},{key:"header:action",fn:function(){return [_vm._v(_vm._s(_vm.$t("action")))]},proxy:true},{key:"status",fn:function(ref){
var item = ref.item;
return [(item.status == 'passed')?_c('v-icon',{attrs:{"large":"","color":"success"}},[_vm._v("mdi-check")]):_vm._e(),(item.status == 'pending')?_c('v-icon',{attrs:{"large":"","color":"primary"}},[_vm._v("mdi-clock-outline")]):_vm._e(),(item.status == 'failed')?_c('v-icon',{attrs:{"large":"","color":"red"}},[_vm._v("mdi-close")]):_vm._e()]}},{key:"action",fn:function(ref){
var item = ref.item;
return [(
          item.exercise_type == 'coloring' ||
            item.exercise_type == 'voice_record'
        )?_c('v-icon',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.showAble(item)}}},[_vm._v("mdi-eye")]):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }