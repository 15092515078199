<template>
  <v-row
    class="align-center justify-center"
    @keyup.enter="$emit('getData', data)"
    v-exit-keyup="clearData"
  >
    <v-col cols="12" sm="6" md="4" lg="2">
      <auto-complete
        @getItem="data.student_id = $event"
        :label="'searchByStudentName'"
        :Store="Student"
        :isObject="true"
        :clearAutocomplete="clearAutocomplete"
        :itemText="'first_name'"
        :itemTextSecond="'last_name'"
      ></auto-complete>
    </v-col>
    <v-col cols="12" sm="6" md="4" lg="2">
      <auto-complete
        @getItem="data.edu_class_id = $event"
        :label="'search by edu class'"
        :Store="eduClass"
        :isObject="true"
        :clearAutocomplete="clearAutocomplete"
      ></auto-complete>
    </v-col>
    <v-col cols="12" sm="6" md="4" lg="2">
      <auto-complete
        @getItem="data.subject_id = $event"
        :label="'search by subject'"
        :Store="Subject"
        :isObject="true"
        :clearAutocomplete="clearAutocomplete"
      ></auto-complete>
    </v-col>
    <v-col cols="12" sm="6" md="4" lg="2">
      <auto-complete
        @getItem="data.grade_id = $event"
        :label="'search by grade'"
        :Store="Grade"
        :isObject="true"
        :clearAutocomplete="clearAutocomplete"
      ></auto-complete>
    </v-col>
    <v-col cols="12" sm="6" md="4" lg="2">
      <auto-complete
        @getItem="data.station_id = $event"
        :label="'search by station'"
        :Store="Station"
        :isObject="true"
        :clearAutocomplete="clearAutocomplete"
      ></auto-complete>
    </v-col>
    <v-col cols="12" sm="6" md="4" lg="2">
      <auto-complete
        @getItem="data.lesson_id = $event"
        :label="'search by lesson'"
        :Store="Lesson"
        :isObject="true"
        :clearAutocomplete="clearAutocomplete"
      ></auto-complete>
    </v-col>
    <v-col cols="12" sm="6" md="4" lg="2">
      <v-select
        v-model="data.exerciseType"
        :label="$t('serach-by-types')"
        :items="exerciseType"
        item-value="value"
        :item-text="$t('text')"
        outlined
        :menu-props="{ closeOnClick: true }"
      ></v-select>
    </v-col>
    <v-col cols="12" sm="6" md="4" lg="2">
      <v-text-field
        type="number"
        v-model="data.student_grade"
        :label="$t('serach by student grade')"
        outlined
      ></v-text-field>
    </v-col>

    <v-col class="mt-n8" lg="2">
      <v-icon
        @click="$emit('getData', data)"
        color="primary"
        class="ma-2"
        x-large
        >mdi-magnify</v-icon
      >
      <v-icon @click="clearData" color="primary" class="ma-2" x-large
        >mdi-close</v-icon
      >
    </v-col>
  </v-row>
</template>
<script>
import { Subject } from "@/store/teacher/subjects";
import { eduClass } from "@/store/teacher/eduClass/index";
import { Grade } from "@/store/teacher/grades";
import { Station } from "@/store/teacher/statoins";
import { Lesson } from "@/store/teacher/lesson";
import { Users as Student } from "@/store/teacher/students/index";
export default {
  directives: {
    "exit-keyup": {
      bind(el, binding, vnode) {
        el.addEventListener("keyup", (event) => {
          if (event.key === "Escape") {
            binding.value(event);
          }
        });
      },
    },
  },
  props: ["ageItems", "individual", "types"],
  data() {
    return {
      Subject,
      eduClass,
      Student,
      Station,
      Lesson,
      Grade,
      data: {},
      menu1: false,
      menu2: false,
      clearAutocomplete: false,
    };
  },
  methods: {
    clearData() {
      this.data = {};
      this.clearAutocomplete = true;
      setTimeout(() => {
        this.clearAutocomplete = false;
      }, 500);
      this.$emit("getData", this.data);
    },
  },
  computed: {
    exerciseType() {
      return [
        { value: "video", text: this.$t("video") },
        { value: "presentation", text: this.$t("presentation") },
        { value: "voice_record", text: this.$t("voice_record") },
        { value: "cross_words", text: this.$t("cross_words") },
        { value: "choose", text: this.$t("choose") },
        { value: "puzzle", text: this.$t("puzzle") },
        { value: "match", text: this.$t("match") },
        { value: "true_or_false", text: this.$t("true_or_false") },
        { value: "reorder", text: this.$t("reorder") },
        { value: "coloring", text: this.$t("coloring") },
        { value: "maze", text: this.$t("maze") },
        { value: "memory", text: this.$t("memory") },
        { value: "categorize", text: this.$t("categorize") },
        { value: "fill_gaps", text: this.$t("fill_gaps") },
      ];
    },
  },
};
</script>
