import { State } from "@/store/state";
import { Request } from "@/network/request";
import i18n from "@/i18n";
import toast from "@/plugins/toast";

export default class solutions {
  endPoint = "teacher/student/solution";
  tableData = {
    data: [],
    showData: [],
    headers: {
      student_name: {
        name: "student name",
        keys: ["student", "full_name"],
      },

      exercise_type: {
        name: "exercise type",
        keys: ["exercise", "type"],
      },
      grade: {
        name: "grade",
        keys: ["grade", "name"],
      },

      lesson: {
        name: "lesson",
        keys: ["lesson", "name"],
      },
      station: {
        name: "station",
        keys: ["station", "name"],
      },
      subject: {
        name: "subject",
        keys: ["subject", "name"],
      },
      student_grade: {
        name: "student grade",
        keys: ["student_grade"],
      },
      grade_to_pass: {
        name: "grade to pass",
        keys: ["exercise", "grade_to_pass"],
      },
      max_grade: {
        name: "max grade",
        keys: ["exercise", "max_grade"],
      },
      created_at: {
        name: "created_at",
        keys: ["created_at"],
      },
      status: {
        slot: "status",
      },
      action: {
        slot: "action",
      },
    },
    meta: {
      total: 1,
      per_page: 1,
      current_page: 1,
    },
  };
  getState = new State();
  showState = new State();
  postState = new State();

  get(params) {
    let request = new Request({
      method: "get",
      endpoint: `/${this.endPoint}`,
      params: params,
      token: this.token,
    });

    this.getState.trigger(
      async () => {
        return await request.send();
      },
      {
        onSuccess: (value) => {
          this.tableData.data = value.data;
          this.tableData.meta = value.meta;
        },
        onError: (error) => {
          console.log(error);
        },
      }
    );
  }
  async show(id) {
    let request = new Request({
      method: "get",
      endpoint: `/${this.endPoint}/${id}`,
      token: this.token,
    });

    await this.showState.trigger(
      async () => {
        return await request.send();
      },
      {
        onSuccess: (value) => {
          this.tableData.showData = value.data;
        },
        onError: (error) => {
          console.log(error);
        },
      }
    );
  }
  async edit(id, data) {
    let request = new Request({
      method: "post",
      endpoint: `/teacher/student/submit/${id}`,
      token: this.token,
      data: data,
    });

    await this.postState.trigger(
      async () => {
        return await request.send();
      },
      {
        onSuccess: (value) => {
          this.tableData.showData = value.data;
        },
        onError: (error) => {
          console.log(error);
        },
      }
    );
  }
}

export const Solution = new solutions();
