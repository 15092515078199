<template>
  <div>
    <solution-filter @getData="getData($event)" />
    <data-table
      @updatePage="getData({ page: $event })"
      :headers="tableData.headers"
      :data="tableData.data"
      :loader="loader"
      :meta="pagination"
    >
      <template #header:status>{{ $t("status") }}</template>

      <template #header:action>{{ $t("action") }}</template>

      <template v-slot:status="{ item }">
        <v-icon large color="success" v-if="item.status == 'passed'"
          >mdi-check</v-icon
        >
        <v-icon large color="primary" v-if="item.status == 'pending'"
          >mdi-clock-outline</v-icon
        >
        <v-icon large color="red" v-if="item.status == 'failed'"
          >mdi-close</v-icon
        >
      </template>

      <template v-slot:action="{ item }">
        <v-icon
          @click="showAble(item)"
          color="primary"
          v-if="
            item.exercise_type == 'coloring' ||
              item.exercise_type == 'voice_record'
          "
          >mdi-eye</v-icon
        >
      </template>
    </data-table>
  </div>
</template>
<script>
import { Solution } from "@/store/teacher/solutions";
import solutionFilter from "./filter.vue";

export default {
  components: {
    solutionFilter,
  },
  data() {
    return {
      Solution,
      filterData: {},
    };
  },
  methods: {
    //get methods
    getData(data) {
      if (Object.keys(data).length == 0) this.filterData = {};
      Object.assign(this.filterData, data);
      Solution.get(this.filterData);
    },
    setDataFilter(data) {
      this.filterData = data;
      this.getData(1);
    },
    async getCountryData(params) {
      if (Country.getState.loading) return;
      await Country.get(params);
      this.countryItems = this.countryItems.concat(this.country);
    },

    //create
    createAble() {
      this.craeteDialog = true;
      this.popupType = "create";
    },
    async create(data) {
      await School.post(data);
      this.craeteDialog = false;
    },

    //copy
    copyAble(obj) {
      this.copyDialog = true;
      this.copyData = obj;
    },
    async copy(data) {
      await School.copy(data.id, { name: data.name });
      this.copyDialog = false;
    },

    //edit
    async editAble(obj) {
      this.editDialog = true;
      await School.show(obj.id);
    },
    async edit(data) {
      await School.edit(data, data.id);
      this.editDialog = false;

      this.getData(1);
    },
    //show
    showAble(obj) {
      if (
        obj.exercise_type == "coloring" ||
        obj.exercise_type == "voice_record"
      ) {
        this.$router.push(`/solution/${obj.id}`);
      } else return false;
    },

    //delete
    deleteAble(obj) {
      this.deleteDialog = true;
      this.deleteDialogData = obj;
    },
    async deleteItem(id) {
      await School.deleteItem(id);
      this.deleteDialog = false;
    },
  },
  computed: {
    //data
    tableData() {
      return Solution.tableData;
    },

    //loaders
    loader() {
      return Solution.getState.loading;
    },

    pagination() {
      return Solution.tableData.meta;
    },
  },
  created() {
    this.getData(1);
  },
};
</script>
