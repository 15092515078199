import { State } from "@/store/state";
import { Request } from "@/network/request";
import i18n from "@/i18n";
import toast from "@/plugins/toast";

export default class subject {
  endPoint = "teacher/subject";
  tableData = {
    data: [],
    showData: [],
    headers: {},
    meta: {
      total: 1,
      per_page: 1,
      current_page: 1,
    },
  };
  getState = new State();

  get(params) {
    let request = new Request({
      method: "get",
      endpoint: `/${this.endPoint}`,
      token: this.token,
      params: params,
    });

    this.getState.trigger(
      async () => {
        return await request.send();
      },
      {
        onSuccess: (value) => {
          this.tableData.data = value.data;
          this.tableData.meta = value.meta;
        },
        onError: (error) => {
          console.log(error);
        },
      }
    );
  }
}

export const Subject = new subject();
