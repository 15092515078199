import { State } from "@/store/state";
import { Request } from "@/network/request";
import i18n from "@/i18n";
import toast from "@/plugins/toast";
import axios from "axios";

export default class educlass {
  endPoint = "teacher/edu_class/index";
  endPoint2 = "teacher/edu_class/show";

  tableData = {
    data: [],
    showData: [],
    headers: {},
    meta: {
      total: 1,
      per_page: 1,
      current_page: 1,
    },
  };
  getState = new State();
  showState = new State();

  get(params) {
    let request = new Request({
      method: "get",
      endpoint: `/${this.endPoint}`,
      token: this.token,
      params: params,
    });

    this.getState.trigger(
      async () => {
        return await request.send();
      },
      {
        onSuccess: (value) => {
          this.tableData.data = value.data;
          this.tableData.meta = value.meta;
        },
        onError: (error) => {
          console.log(error);
        },
      }
    );
  }
  show(id) {
    let request = new Request({
      method: "get",
      endpoint: `/${this.endPoint2}/${id}`,
      token: this.token,
    });

    this.showState.trigger(
      async () => {
        return await request.send();
      },
      {
        onSuccess: (value) => {
          this.tableData.showData = value.data;
        },
        onError: (error) => {
          console.log(error);
        },
      }
    );
  }
}

export const eduClass = new educlass();
