import { State } from "@/store/state";
import { Request } from "@/network/request";

export default class lesson {
  endPoint = "teacher/lesson";
  tableData = {
    data: [],
    showData: [],
    meta: {
      total: 1,
      per_page: 1,
      current_page: 1,
    },
  };
  getState = new State();
  editState = new State();

  get(params) {
    let request = new Request({
      method: "get",
      endpoint: `/${this.endPoint}`,
      token: this.token,
      params: params,
    });

    this.getState.trigger(
      async () => {
        return await request.send();
      },
      {
        onSuccess: (value) => {
          this.tableData.data = value.data;
          this.tableData.meta = value.meta;
        },
        onError: (error) => {
          console.log(error);
        },
      }
    );
  }
  edit({ id, payload, onSuccess = () => {}, onError = () => {} }) {
    let request = new Request({
      method: "put",
      endpoint: `/${this.endPoint}/changeStatus/${id}`,
      data: payload,
    });

    this.editState.trigger(
      async () => {
        return await request.send();
      },
      {
        onSuccess: () => {
          onSuccess();
        },
        onError: (error) => {
          onError();
          console.log(error);
        },
      }
    );
  }
}

export const Lesson = new lesson();
